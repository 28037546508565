import React from "react"

import Project from "../components/Pages/Project"

import "../styles/styles.css"

const ProjectPage = props => {
  return (
    <Project
      {...props}
      project={props.data.project.frontmatter}
      projectsList={props.data.projectsList.edges}
      headerImg={props.data.headerImg}
      page={props.data.page}
    />
  )
}

export const pageQuery = graphql`
  query ProjectById($id: String!) {
    headerImg: imageSharp(original: { src: { regex: "/.*rectanges.*/" } }) {
      id
      fixed(height: 450, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/pages/portfolio/" } }) {
      frontmatter {
        subtitle
      }
    }
    projectsList: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            icon {
              childImageSharp {
                fluid(maxWidth: 50, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    project: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      id
      frontmatter {
        title
        subtitle

        featuredImage {
          childImageSharp {
            fluid(maxWidth: 380, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subProject {
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectPage
