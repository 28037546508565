import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles.module.css"

const ProjectSection = ({ title, description, image, alignment = "left" }) => (
  <section className={`${styles.section} ${styles[alignment]}`}>
    <div className={styles.container}>
      <div className={styles.subsection}>
        <h1 className={styles.title}>
          {title}
          <Pontuation>.</Pontuation>
        </h1>
        <ReactMarkdown className={styles.description} source={description} />
      </div>
      <div className={styles.subsection}>
        {image && <Img fluid={image} className={styles.featuredImg} />}
      </div>
    </div>
  </section>
)

export default ProjectSection
