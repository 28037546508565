import React from "react"
import Img from "gatsby-image"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"
import ProjectCard from "../ProjectCard/ProjectCard"
import ProjectSection from "../ProjectSection/ProjectSection"

import styles from "./styles/project.module.css"

const Project = ({ project, projectsList, headerImg, page, ...props }) => {
  return (
    <SimpleLayout path={props.path}>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 className={styles.sectionTitle}>
              {project.title}
              <Pontuation>.</Pontuation>
            </h1>
            <h2 className={styles.sectionDescription}>{project.subtitle}</h2>
          </div>
          <div className={styles.rigth}>
            <Img fixed={headerImg.fixed} className={styles.headerImg} />
            {project.featuredImage && (
              <Img
                fluid={project.featuredImage.childImageSharp.fluid}
                className={styles.featuredImg}
              />
            )}
          </div>
        </div>
      </header>
      {(project.subProject || []).map((subProject, i) => (
        <ProjectSection
          alignment={i % 2 !== 0 ? "left" : "right"}
          title={subProject.title}
          description={subProject.description}
          image={subProject.featuredImage && subProject.featuredImage.childImageSharp.fluid}
        />
      ))}

      <section className={styles.moreProjects}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            More projects<Pontuation>.</Pontuation>
          </h1>
          <h2 className={styles.sectionDescription}>{page.subtitle}</h2>
          <div className={styles.cards}>
            {projectsList.map(({ node }) => (
              <ProjectCard
                key={node.id}
                slug={node.fields.slug}
                active={node.frontmatter.title === project.title}
                icon={node.frontmatter.icon.childImageSharp.fluid}
                title={node.frontmatter.title}
                description={node.frontmatter.subtitle}
              />
            ))}
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

export default Project
